























import Vue from 'vue'

interface ISensorObjectList {
  radars: any[]
  rfs: any[]
  acoustics: any[]
  cameras: any[]
  dsx: any[]
  cannons: any[]
}

interface IIconObject {
  count: number
  icon: string
}

interface IIconGrid {
  rows: number
  columns: number
}

interface IIconDimensions {
  width: number
  height: number
}

const props = {
  sensors: {
    type: Object as () => ISensorObjectList,
    default: {
      radars: [],
      rfs: [],
      acoustics: [],
      cameras: [],
      dsx: [],
      cannons: []
    }
  },
  id: {
    type: Number,
    default: 0
  }
}

const MAPPED_TYPES = ['cannons', 'radars', 'rfs', 'cameras', 'acoustics', 'dsx']

export default Vue.extend({
  name: 'MilStd2525SentryIcon',
  props,
  data() {
    return {
      iconHeight: 43.5,
      iconWidth: 50,
      iconGutter: 4.5,
      imageDimensions: [0, 0],
      iconTopBarHeight: 10
    }
  },
  computed: {
    dimensions(): IIconDimensions {
      return { width: this.width, height: this.height }
    },
    hasEngaged(): boolean {
      return this.sentryIcons.some(item => {
        return item.icon.includes('engaged')
      })
    },
    width(): number {
      return this.iconWidth * this.grid.columns
    },
    height(): number {
      return (
        this.iconHeight * this.grid.rows +
        this.grid.rows * this.iconGutter +
        (this.hasEngaged ? this.iconTopBarHeight + this.iconGutter : 0)
      )
    },
    grid(): IIconGrid {
      const rows = Math.floor(Math.sqrt(this.sentryIcons.length))
      const columns = Math.floor(Math.ceil(this.sentryIcons.length / rows)) || 0
      return {
        rows,
        columns
      }
    },
    sentryIcons() {
      let sensors: IIconObject[] = []
      MAPPED_TYPES.forEach(key => {
        this.sensors[key].forEach(sensor => {
          const icon = this.getIcon(sensor, key)
          const itemIndex = sensors.findIndex(item => {
            return item.icon === icon
          })
          if (itemIndex !== -1) {
            sensors[itemIndex].count += 1
          } else {
            const obj: IIconObject = {
              count: 1,
              icon: icon
            }
            sensors.push(obj)
          }
        })
      })
      return sensors
    }
  },
  methods: {
    getIcon(sensor, type): string {
      let color = sensor.status_color
      if (color === 'orange') {
        color = 'yellow'
      }
      switch (type) {
        case 'rfs':
          return `rf-${color}`
        case 'radars':
          return `radar-${color}`
        case 'cannons':
          return `cannon-${color}${
            sensor?.power_trigger_engaged ? '-engaged' : ''
          }`
        case 'acoustics':
          return `acoustic-${color}`
        case 'cameras':
          return `camera-${color}`
        case 'dsx':
          return `generic-${color}`
      }
    }
  },
  watch: {
    sentryIcons: {
      handler: function() {
        this.$emit('updated:dimensions', this.dimensions)
      },
      immediate: true
    }
  }
})
